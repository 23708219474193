import PropTypes from 'prop-types';
import React from 'react';
import { getSubmission, submitToCoreHR } from '@API/Integrations/CoreHRAPI';
import { retryableAPICall } from '@API/common-api-utils';
import Integration from './Integration';

const CoreHRInputConfig = [
  {
    id: 'payRateType',
    label: 'Pay Rate Type',
    type: 'select',
    required: true,
    options: [
      { label: 'Annual', value: 'A' },
      { label: 'Hourly', value: 'H' },
    ],
  },
  {
    id: 'rateOfPay',
    label: 'Rate of Pay',
    type: 'select',
    required: true,
    type: 'number',
    defaultValue: 0.0,
    inputProps: {
      min: 0,
      step: 0.1,
    },
  },
  {
    id: 'startDate',
    label: 'Start Date',
    required: true,
    type: 'date',
  },
  {
    id: 'endDate',
    label: 'End Date',
    type: 'date',
  },
  {
    id: 'probationMonths',
    label: 'Probation Months',
    type: 'select',
    required: true,
    options: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
    ],
  },
];

function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;

  if (isComplete) {
    convertedValue = formData[id];
  }

  return { value: convertedValue, url, isDisabled, isHidden: false };
}

function CoreHRForm({ applicantId }) {
  const formSubmit = async (applicantId, formData) => {
    try {
      const { status, message, supplementaryDataFields } = await retryableAPICall(() =>
        submitToCoreHR(applicantId, {
          dataFields: {
            payRateType: formData.payRateType,
            rateOfPay: formData.rateOfPay,
            startDate: formData.startDate,
            endDate: formData.endDate,
            probationMonths: formData.probationMonths,
          },
        }),
      );
      return status === 'NOT_FOUND_ERROR'
        ? 'NOT_FOUND_ERROR'
        : { status: status, message, data: supplementaryDataFields?.dataFields };
    } catch (error) {
      console.error('Error during form submission:', error);
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  const handleChange = (id, value, formData, setFormData) => {
    const updatedData = { ...formData, [id]: value };

    setFormData(updatedData);
  };

  async function getCoreHRData(applicantId) {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === 'NOT_FOUND_ERROR') {
        return 'NOT_FOUND_ERROR';
      }

      const { status, message, supplementaryDataFields } = resp;

      if (status !== 'SUCCESS') {
        return {
          status: status,
          message: message,
          data: supplementaryDataFields?.dataFields,
        };
      }

      return {
        status: 'SUCCESS',
        data: supplementaryDataFields?.dataFields,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const validationConfig = () => {
    return CoreHRInputConfig.reduce((acc, { id, type, required, inputProps }) => {
      const { min, max } = inputProps || {};
      return [...acc, { id, required, type, ...(type === 'number' ? { type, min, max } : {}) }];
    }, []);
  };

  return (
    <Integration
      title="CoreHR"
      applicantId={applicantId}
      valueSetter={valueSetter}
      integrationName="CoreHR"
      formConfig={CoreHRInputConfig}
      formSubmit={formSubmit}
      getSubmission={getCoreHRData}
      componentName="CoreHR"
      customOnChange={handleChange}
      getValidationConfig={validationConfig}
    />
  );
}

CoreHRForm.propTypes = {
  applicantId: PropTypes.string,
};

CoreHRForm.defaultProps = {
  applicantId: null,
};

export default CoreHRForm;
