import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { coreHR },
} = config;

export async function getSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${coreHR.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function submitToCoreHR(candidateId, data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(`${api.middlewareAPIURL}${coreHR.submitPath}/${candidateId}`, data, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
